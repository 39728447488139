import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";

import { Observable, from, of } from "rxjs";
import { catchError, map, filter, take, mergeMap } from "rxjs/operators";

import { toTeamMember, TeamMember } from "../../features/teamPage/team-member";

import { NoAuthOrganizationSummaryService } from "../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { NoAuthUserSummaryService } from "@onsip/common/services/api/resources/noAuthUserSummary/no-auth-user-summary.service";

@Injectable({ providedIn: "root" })
export class PersonalPageRouteResolver {
  constructor(
    private router: Router,
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private noAuthUserSummaryService: NoAuthUserSummaryService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TeamMember | false> {
    /**
     * Dealing with 2 domains here: the "fake" domain and the one in our database
     * for most orgs theyre the same, but for orgs with custom domains this is not the case
     * custom domains need to do a NoAuthOrganizationSummaryRead to fetch the real domain to pass back to NoAuthUserSummaryRead, which is address-validated
     *
     * ...and that's the story behind this piece of code
     */
    return this.noAuthOrgSummaryService.state.pipe(
      filter(noAuthState => !noAuthState.loading),
      map(noAuthState => Object.values(noAuthState.state)[0].domain),
      take(1),
      mergeMap(Domain => {
        return from(
          this.noAuthUserSummaryService.noAuthUserSummaryRead({
            Username: route.paramMap.get("username")?.toLocaleLowerCase() || "",
            Domain
          })
        ).pipe(
          map(innerResult => {
            if (innerResult.status === "success") {
              const user = Object.values(innerResult.data)[0];
              return toTeamMember(user);
            } else {
              this.router.navigate(["/not-person"], { skipLocationChange: true });
              return false;
            }
          }),
          catchError(() => {
            this.router.navigate(["/not-person"], { skipLocationChange: true });
            return of(false as false);
          })
        );
      })
    );
  }
}
