import { NgZone } from "@angular/core";
import { Observable } from "rxjs";

/**
 * Angular ngZone patches http XHR request callbacks, and few others, to kick in change detection.
 * But there are many asynchronous operations which are not covered by the ngZone and after
 * successful callback you do not see any updates in UI. To manually run those operations
 * you need to wrap them inside ngZone run() method.
 *
 * When you work with RxJS observables, it is not easy to maintain chain-ability of multiple operators
 * calls with ngZone.run() wrapping. Since RxJS 5.5 you can build your own custom pipeable operators.
 * A RxJS pipeable operator is a function that returns a function with the signature:
 *  <T, R>(source: Observable<T>) => Observable<R>
 * Below is the code snippet for a pipeable operator which let the observables to enter into
 * angular zone so that the change detection works properly.
 */
export function enterZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>(observer =>
      source.subscribe({
        next: value => zone.run(() => observer.next(value)),
        error: (error: unknown) => observer.error(error),
        complete: () => observer.complete()
      })
    );
}
