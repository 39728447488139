import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";

import { Observable, Subscription, of, combineLatest, BehaviorSubject } from "rxjs";
import { filter, map, startWith, take } from "rxjs/operators";

import { TeamMember, toTeamMember } from "../team-member";
import { DomainService } from "../domain.service";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { NoAuthUserSummaryService } from "../../../../common/services/api/resources/noAuthUserSummary/no-auth-user-summary.service";

import { LogService } from "../../../../common/services/logging/log.service";

import { SaysoEventData } from "../../../../common/interfaces/analytics";
import { AnalyticsService } from "../analytics.service";

const debug = false;

@Component({
  selector: "onsip-team-view-container",
  templateUrl: "./team-view-container.component.html",
  styleUrls: ["./team-view-container.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamViewContainerComponent implements OnInit, OnDestroy {
  teamMembers = new BehaviorSubject<Array<TeamMember>>([]);
  hasNoTeamMembers = new BehaviorSubject<boolean>(false);
  sideNavView!: Observable<boolean>;
  didComplete!: Observable<boolean>;

  private unsubscriber = new Subscription();

  constructor(
    private log: LogService,
    private router: Router,
    private domainService: DomainService,
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private noAuthUserSummaryService: NoAuthUserSummaryService,
    private analyticsService: AnalyticsService,
    private breakpoint: BreakpointObserver
  ) {}

  ngOnInit(): void {
    debug && this.log.debug("TeamViewContainerComponent.ngOnInit");
    this.noAuthUserSummaryService
      .noAuthUserSummaryBrowse({ Domain: this.domainService.get() })
      .then(state => {
        if (state.status === "success") {
          const users = Object.values(state.data);
          this.teamMembers.next(
            users
              .map(user => toTeamMember(user))
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              )
          );
          this.hasNoTeamMembers.next(!users.length);
        } else {
          this.noAuthUserSummaryService.clearErrors();
          return of([]);
        }
      });

    // set title right out of the gate and also any time we navigate home
    this.unsubscriber.add(
      combineLatest([
        this.noAuthOrgSummaryService.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0]?.contactOrganization)
        ),
        this.router.events.pipe(
          filter(e => e instanceof NavigationEnd),
          filter(e => (e as NavigationEnd).url === "/"),
          startWith(() => true)
        )
      ]).subscribe(([orgName]) => (document.title = `${orgName}'s Team Page`))
    );

    // Analytics
    this.unsubscriber.add(
      this.noAuthOrgSummaryService.state
        .pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0]?.organizationId),
          take(1)
        )
        .subscribe(orgId => {
          if (orgId) {
            const eventData: SaysoEventData = {
              action: "load",
              product: "teampage",
              button_type: "element",
              organization_id: parseInt(orgId)
            };
            this.analyticsService.trackEvent(eventData);
            eventData.action = "seen";
            this.analyticsService.trackEvent(eventData);
          }
        })
    );

    this.didComplete = this.noAuthOrgSummaryService.state.pipe(map(state => !state.loading));
    this.sideNavView = this.breakpoint
      .observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium])
      .pipe(map(state => state.matches));
  }

  ngOnDestroy(): void {
    debug && this.log.debug("TeamViewContainerComponent.ngOnDestroy");
    this.unsubscriber.unsubscribe();
  }
}
