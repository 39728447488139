import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Observable, Subscription, combineLatest, BehaviorSubject } from "rxjs";
import { pluck, take, skip, map, filter } from "rxjs/operators";

import { AnalyticsService } from "../analytics.service";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { TeamMember } from "../team-member";

import { SaysoEventData } from "../../../../common/interfaces/analytics";

@Component({
  selector: "onsip-personal-view-container",
  templateUrl: "./personal-view-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalViewContainerComponent implements OnInit, OnDestroy {
  teamMember!: Observable<TeamMember>;
  private unsubscriber = new Subscription();
  private mediaAvailable: BehaviorSubject<{
    audioAvailable: boolean;
    videoAvailable: boolean;
  }> = new BehaviorSubject<{ audioAvailable: boolean; videoAvailable: boolean }>({
    audioAvailable: false,
    videoAvailable: false
  });

  constructor(
    private route: ActivatedRoute,
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.teamMember = this.route.data.pipe(pluck("teamMember"));

    this.unsubscriber.add(
      combineLatest([
        this.teamMember,
        this.noAuthOrgSummaryService.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0].contactOrganization)
        )
      ]).subscribe(
        ([teamMember, orgName]) =>
          (document.title = teamMember.name
            ? `${teamMember.name} from ${orgName}`
            : orgName
            ? orgName
            : "")
      )
    );

    // Analytics
    this.unsubscriber.add(
      combineLatest([
        this.teamMember,
        this.noAuthOrgSummaryService.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0].organizationId)
        ),
        this.mediaAvailable.pipe(skip(1), take(1))
      ])
        .pipe(take(1))
        .subscribe(([teamMember, orgId, { audioAvailable, videoAvailable }]) => {
          if (orgId) {
            const eventData: SaysoEventData = {
              action: "load",
              product: "personalpage",
              button_type: "element",
              target: teamMember.userId,
              target_type: "user",
              audio_available: audioAvailable,
              video_available: videoAvailable,
              audio_config: audioAvailable,
              video_config: videoAvailable,
              organization_id: parseInt(orgId)
            };
            this.analytics.trackEvent(eventData);
            eventData.action = "seen";
            this.analytics.trackEvent(eventData);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  onAnalyticsData([audioAvailable, videoAvailable]: Array<boolean>): void {
    this.mediaAvailable.next({ audioAvailable, videoAvailable });
  }
}
