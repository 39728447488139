import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { withLatestFrom, map, filter, take } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import { APIUserAddress, UserAddress, apiUserAddressToUserAddress as clean } from "./user-address";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiAddAction, ApiBrowseAction, ApiDeleteAction, ApiEditAction } from "../../api-actions";
import { ParameterValue } from "../../util/api-action-description";

import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { organizationId } from "../../apiParams/organization-id";
import { domain } from "../../apiParams/domain";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { UserAddress };

const debug = false;

@Injectable({ providedIn: "root" })
export class UserAddressService extends ApiResourceService<UserAddress> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserAddress", "userAddressId");
    debug && this.state.subscribe(state => console.warn("UserAddressService", state));
  }

  /** public getter to get a filtered observable with just the self user */
  get selfUser(): Observable<Array<UserAddress>> {
    return this.state.pipe(
      withLatestFrom(this.store.state.pipe(userId())),
      map(([substate, selfUserId]) =>
        Object.values(substate.state).filter(userAddress => userAddress.userId === selfUserId)
      ),
      filter(<T>(userAddresses: T): userAddresses is NonNullable<T> => !!userAddresses)
    );
  }

  get currentOrganizationUserAddresses(): Observable<Array<UserAddress>> {
    return combineLatest({
      addresses: this.state.pipe(filter(state => !state.loading)),
      domain: this.store.state.pipe(domain())
    }).pipe(
      map(({ addresses, domain }) =>
        Object.values(addresses.state).filter(address => address.domain === domain)
      )
    );
  }

  // TODO paginate this for bigger orgs
  userAddressBrowse(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.UserAddressBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Limit: 2500,
        Orderby: "UserAddressId",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.UserAddressBrowse);
  }

  // we should have a org only user address browse
  userAddressBrowseWithOrgId(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.UserAddressBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 2500,
        OrderBy: "UserAddressId",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.UserAddressBrowse);
  }

  userAddressEdit(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserAddressEdit,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserAddressEdit);
  }

  userAddressEditDefaultAddress(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserAddressEditDefaultAddress,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserAddressEditDefaultAddress);
  }

  userAddressEditDoNotDisturb(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserAddressEditDoNotDisturb,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserAddressEditDoNotDisturb);
  }

  userAddressAdd(
    Username: string,
    UserId: string,
    Timeout = 30,
    extraParameters?: Record<string, ParameterValue> // DefaultAddress should go here
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.UserAddressAdd,
        Domain: this.store.state.pipe(domain()),
        OrganizationId: this.store.state.pipe(organizationId()),
        SessionId: this.store.state.pipe(sessionId()),
        UserId,
        Username,
        Timeout,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAddAction.UserAddressAdd);
  }

  userAddressDelete(
    Address: string,
    UserAddressId: string,
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.UserAddressDelete,
        Address,
        SessionId: this.store.state.pipe(sessionId()),
        UserAddressId,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.UserAddressDelete);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.UserAddressBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<APIUserAddress>>(
              response,
              action,
              "UserAddress",
              "UserAddresses"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiEditAction.UserAddressEditDefaultAddress:
      case ApiEditAction.UserAddressEditDoNotDisturb:
      case ApiEditAction.UserAddressEdit:
      case ApiAddAction.UserAddressAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<APIUserAddress>(response, action, "UserAddress"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.UserAddressDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedAddress = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "UserAddressId")?.Value;
        deletedAddress &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedAddress]: undefined }, action);
        break;
      case ApiDeleteAction.UserDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedUser = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "UserId")?.Value;
        if (deletedUser) {
          this.state
            .pipe(
              map(state => Object.values(state.state)),
              take(1)
            )
            .subscribe(userAddress => {
              const deletedAddresses = userAddress.filter(
                address => address.userId === deletedUser
              );
              deletedAddresses.forEach(deletedAddress =>
                this.store.mergeStateUpdate(
                  this.resourceName,
                  {
                    [deletedAddress.userAddressId]: undefined
                  },
                  action
                )
              );
            });
        }
        break;
    }
  }
}
