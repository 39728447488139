<div class="bar"
  [style.background]="color | async"
  [class.mobile]="(sideNavView | async) === false">
  <div [style.color]="(color | async)! | textColorForCustomBackground">
    <ng-container *ngIf="greeting | async as asyncGreeting; else noGreeting">
      <h2>{{asyncGreeting}}</h2>
    </ng-container>
    <ng-template #noGreeting>
      <h2>Welcome to the {{ orgName | async }} team!</h2>
    </ng-template>
  </div>
  <p *ngIf="sideNavView | async"
    [style.color]="(color | async)! | textColorForCustomBackground">
    {{blurb | async}}
  </p>
  <img *ngIf="logoUrl | async" [src]="logoUrl | async" alt="Logo Image"/>
</div>
