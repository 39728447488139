import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DomainService {
  domain: string;

  constructor() {
    if (!/localhost|55-broad-1|app-dev\.onsip\.com/.test(location.hostname)) {
      this.domain = location.hostname;
    } else {
      const domain = getParameterByName("domain");
      if (domain) {
        this.domain = domain;
      } else {
        // DEVELOPER: hardcode the domain right here, for when you don't set a query parameter
        console.error(
          "DEVELOPER:\n\n" +
            "You can can use a query param of 'domain=' to override the domain in testing environments\n\n" +
            "You can also use an additional query param of 'username=' to navigate directly to a personal page"
        );
        this.domain = "team.onsip.com";
      }
    }
  }

  get(): string {
    return this.domain;
  }
}

function getParameterByName(name: string): string {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results: RegExpExecArray | null = regex.exec(location.search);
  // eslint-disable-next-line no-null/no-null
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
