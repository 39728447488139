import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

@Component({
  selector: "onsip-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.scss"]
})
export class SideBarComponent implements OnInit {
  blurb!: Observable<string | undefined>;
  greeting!: Observable<string | undefined>;
  color!: Observable<string | undefined>;
  orgName!: Observable<string | undefined>;
  logoUrl!: Observable<string | undefined>;

  sideNavView!: Observable<boolean>;

  constructor(
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private breakpoint: BreakpointObserver
  ) {}

  ngOnInit() {
    this.blurb = this.noAuthOrgSummaryService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.teamPageInfo.blurb)
    );
    this.greeting = this.noAuthOrgSummaryService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.teamPageInfo.greeting)
    );

    this.color = this.noAuthOrgSummaryService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.teamPageInfo.accentColor)
    );

    this.orgName = this.noAuthOrgSummaryService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.contactOrganization)
    );

    this.logoUrl = this.noAuthOrgSummaryService.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.organizationAvatarUrl)
    );

    this.sideNavView = this.breakpoint
      .observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium])
      .pipe(map(state => state.matches));
  }
}
