import { CallContext } from "./store/call-context";

interface OnSIP {
  do?: (args?: CommandArgs) => void;
  error?: Error;
  filterList?: Array<string>;
  filters?: Record<string, (...args: Array<any>) => boolean>;
  filterWaitForLookup?: Record<string, Array<string>>;
  filterWaitFors?: Record<string, (...args: Array<any>) => Promise<any>>;
  evaledWaitFors?: Record<string, Promise<any>>;
  q?: Array<CommandArgs>;
  date?: Date;
  location?: OnSIPLocation;
  context?: CallContext;
  ignoredCookies?: Array<string>;
}

export interface OnSIPWindow extends Window {
  onsip: OnSIP;
}

export interface OnSIPLocation {
  country_code: string;
}

export interface CommandArgs extends IArguments {
  [0]: string;
}

const onsipGlobal = "onsip";
(window as any)[onsipGlobal] = (window as any)[onsipGlobal] || {};
export const onsip: OnSIP = (window as any)[onsipGlobal];

export function setLocation(location: OnSIPLocation): void {
  onsip.location = location;
}

export function getLocation(): OnSIPLocation | undefined {
  return onsip.location;
}

export function getError(): Error | undefined {
  return onsip.error;
}

export function setError(error: Error): void {
  onsip.error = error;
}

export function getDate(): Date | undefined {
  return onsip.date;
}

export function setDate(date: Date): void {
  onsip.date = date;
}

export function getContext(): CallContext | undefined {
  return onsip.context;
}

export function setContext(context: CallContext): void {
  onsip.context = context;
}

export function getIgnoredCookies(): Array<string> {
  return onsip.ignoredCookies || [];
}

export function addIgnoredCookies(cookie: string | Array<string>, ...cookies: Array<string>): void {
  onsip.ignoredCookies = onsip.ignoredCookies || [];
  onsip.ignoredCookies = onsip.ignoredCookies.concat(cookie, ...cookies);
}

export const ignorableCookies = {
  _GA: "_ga", // Google
  HUBSPOTUTK: "hubspotutk" // HubSpot
};

export function getCookieValue(cookie: string, key: string): string | undefined {
  const match = cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return match ? match.pop() : undefined;
}

export function getCommandQueue(): Array<CommandArgs> | undefined {
  return onsip.q;
}

export function _do(args?: CommandArgs): void {
  onsip.do && onsip.do(args);
}
