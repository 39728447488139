import {
  ApiNoAuthUserSummaryAddress,
  apiNoAuthUserSummaryAddressToAddress,
  NoAuthUserSummaryAddress
} from "../../apiResponse/address";
import {
  onsipApiArrayToArray,
  OnsipAPIBoolean,
  onsipApiBooleanToBoolean,
  OnsipAPINotEmptyArray
} from "../../apiResponse/xml-json";

export declare interface ApiNoAuthUserSummary {
  Addresses: OnsipAPINotEmptyArray<"Address", ApiNoAuthUserSummaryAddress>;
  Blurb: string;
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string;
  Domain: string;
  ExposePii: OnsipAPIBoolean;
  Facebook: string;
  Linkedin: string;
  OrganizationId: string;
  TeamPageVisible: OnsipAPIBoolean;
  Title: string;
  Twitter: string;
  UserAvatarUrl: string;
  UserId: string;
}

export declare interface NoAuthUserSummary {
  addresses: Array<NoAuthUserSummaryAddress>;
  blurb: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  domain: string;
  exposePii: boolean;
  facebook: string;
  linkedin: string;
  organizationId: string;
  teamPageVisible: boolean;
  title: string;
  twitter: string;
  userAvatarUrl: string;
  userId: string;
}

export function apiNoAuthUserSummaryToNoAuthUserSummary(
  apiNoAuthUserSummary: ApiNoAuthUserSummary
): NoAuthUserSummary {
  return {
    addresses: onsipApiArrayToArray(apiNoAuthUserSummary.Addresses, "Address").map(
      apiNoAuthUserSummaryAddressToAddress
    ),
    blurb: apiNoAuthUserSummary.Blurb,
    contactEmail: apiNoAuthUserSummary.ContactEmail,
    contactName: apiNoAuthUserSummary.ContactName,
    contactPhone: apiNoAuthUserSummary.ContactPhone,
    domain: apiNoAuthUserSummary.Domain,
    exposePii: onsipApiBooleanToBoolean(apiNoAuthUserSummary.ExposePii),
    facebook: apiNoAuthUserSummary.Facebook,
    linkedin: apiNoAuthUserSummary.Linkedin,
    organizationId: apiNoAuthUserSummary.OrganizationId,
    teamPageVisible: onsipApiBooleanToBoolean(apiNoAuthUserSummary.TeamPageVisible),
    title: apiNoAuthUserSummary.Title,
    twitter: apiNoAuthUserSummary.Twitter,
    userAvatarUrl: apiNoAuthUserSummary.UserAvatarUrl,
    userId: apiNoAuthUserSummary.UserId
  };
}
