<onsip-element #webComponentEl
  [attr.accent-color]="color"
  [attr.audio]="audio"
  [attr.audio-available]="(audioAvailable && (!video || (video && videoAvailable))) ? 'true' : 'false'"
  [attr.autostart]="true"
  [attr.delay]="0"
  [attr.organization-id]="organizationId"
  [attr.target]="target"
  [attr.target-type]="targetType"
  [attr.target-display]="targetDisplay"
  [attr.video]="video"
  [attr.video-available]="videoAvailable ? 'true' : 'false'"
  [attr.voicemailbox]="voicemailbox"
  [attr.allow-screenshare]="allowScreenshare"
  class="onsip-element-{{target}}"
  class="pointer">
  <button mat-stroked-button
    id="onsip-element-{{target}}-{{video ? 'video-' : ''}}{{audio}}"
    [style.color]="color | textColorForWhiteBackground"
    [disabled]="disabled">
    <mat-icon *ngIf="video; else noVideo">videocam</mat-icon>
    <ng-template #noVideo>
      <mat-icon>phone</mat-icon>
    </ng-template>
    <span *ngIf="!video" [class.grid]="grid">&nbsp;&nbsp;Let's Talk</span>
  </button>
</onsip-element>
