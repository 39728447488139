import { Component, ChangeDetectionStrategy, NgZone } from "@angular/core";

import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { AFKService } from "../warningBar/afk.service";
import { PersonalPageRouteGuard } from "../../../app/teamPage/personal-page-route.guard";

import { TeamMemberComponent } from "../team-member.component";
import { AnalyticsService } from "../analytics.service";

@Component({
  selector: "onsip-personal-view",
  templateUrl: "./personal-view.component.html",
  styleUrls: ["./personal-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonalViewComponent extends TeamMemberComponent {
  constructor(
    zone: NgZone,
    noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    afkService: AFKService,
    analyticsService: AnalyticsService,
    public personalPageRouteGuard: PersonalPageRouteGuard
  ) {
    super(zone, noAuthOrgSummaryService, afkService, analyticsService);
  }
}
