import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { DomainService } from "../../features/teamPage/domain.service";

@Injectable({ providedIn: "root" })
export class PersonalPageRouteGuard {
  private force = false;

  constructor(
    private router: Router,
    // @ts-ignore: unused
    private domain: DomainService // HACK to get domain service check to come first for query params method
  ) {}

  canActivate() {
    if (/localhost|55-broad-1|app-dev\.onsip\.com/.test(location.hostname)) {
      const username = getParameterByName("username");
      if (username) {
        this.router.navigate([`/${username}`], { skipLocationChange: true });
        return false;
      } else {
        console.error(
          "Domain detected as localhost and no 'username' query param supplied- skipping personal page route checking"
        );
        return true;
      }
    } else if (location.pathname === "/" || this.force) {
      this.force = false;
      return true;
    } else {
      this.router.navigate([`/${location.pathname.split("/")[1]}`], { skipLocationChange: true });
      return false;
    }
  }

  goHome(options?: NavigationExtras): Promise<boolean> {
    this.force = true;
    return this.router.navigate(["/"], options);
  }
}

function getParameterByName(name: string): string {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results: RegExpExecArray | null = regex.exec(location.search);
  // eslint-disable-next-line no-null/no-null
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
