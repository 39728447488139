<mat-card personal-page-card class="mat-elevation-z0">
  <div class="personal-view-accent-strip" [style.background]="color | async"></div>
  <div class="image-container">
    <img mat-card-avatar
      *ngIf="userAvatarUrl; else defaultAvatar"
      [src]="userAvatarUrl"
      class="avatar"
      alt="Avatar Image"/>
    <ng-template #defaultAvatar>
      <mat-icon mat-card-avatar class="default avatar">account_circle</mat-icon>
    </ng-template>
  </div>
  <div class="mat-card-content-actions">
    <mat-card-content>
      <div class="personal-view-name-container">
        <h1>{{name}}</h1>
        <div
          class="dot circle"
          [class.available]="(globallyAvailable | async) || ((audioAvailable | async) && (!(elementDisabled | async)!.disabled || (elementDisabled | async)!.reason === 'in-use'))"
          [class.busy]="busy | async">
        </div>
      </div>
      <h2 *ngIf="title">{{title}}</h2>
      <p *ngIf="blurb">{{blurb}}</p>
    </mat-card-content>
    <mat-card-actions>
      <div class="personal-view-element-container">
        <onsip-element-component
          [target]="userId"
          [targetDisplay]="name"
          [organizationId]="oid"
          [audio]="(audioAvailable | async)!"
          [video]="false"
          [audioAvailable]="(globallyAvailable | async)! && ((audioAvailable | async)! || ((busy | async) === false))"
          [videoAvailable]="(globallyAvailable | async)! && ((videoAvailable | async)! || ((busy | async) === false))"
          [color]="(color | async)!"
          [disabled]="(elementDisabled | async)!.disabled">
        </onsip-element-component>
        <onsip-element-component
          [target]="userId"
          [targetDisplay]="name"
          [organizationId]="oid"
          [audio]="(audioAvailable | async)!"
          [video]="true"
          [audioAvailable]="(globallyAvailable | async)! && ((audioAvailable | async)! || ((busy | async) === false))"
          [videoAvailable]="(globallyAvailable | async)! && ((videoAvailable | async)! || ((busy | async) === false))"
          [color]="(color | async)!"
          [disabled]="(elementDisabled | async)!.disabled">
        </onsip-element-component>
      </div>
      <a class="personal-view-email"
        [href]="'mailto:' + email"
        target="_blank">
        <span>{{email}}</span>
      </a>
      <div class="personal-view-social-media-container">
        <a mat-icon-button
        class="social-media"
        *ngIf="linkedin"
        [href]="'https://www.linkedin.com/in/' + linkedin"
        target="_blank"
        rel="external">
          <mat-icon svgIcon="linkedin_icon" aria-label="linkedin"></mat-icon>
        </a>
        <a mat-icon-button
        class="social-media"
        *ngIf="facebook"
        [href]="'https://facebook.com/' + facebook"
        target="_blank"
        rel="external">
          <mat-icon svgIcon="facebook_icon" aria-label="facebook"></mat-icon>
        </a>
        <a mat-icon-button
        class="social-media"
        *ngIf="twitter"
        [href]="'https://twitter.com/' + twitter"
        target="_blank"
        rel="external">
          <mat-icon svgIcon="twitter_icon" aria-label="twitter"></mat-icon>
        </a>
      </div>
      <button mat-button
        class="personal-view-back-button"
        (click)="personalPageRouteGuard.goHome()">
        <mat-icon aria-label="back button">keyboard_backspace</mat-icon>
        <a>Back to team page</a>
      </button>
    </mat-card-actions>
  </div>
</mat-card>
