<mat-card teampage-whitepage-card class="mat-elevation-z0">
  <mat-card-content>
    <div class="gear-container">
      <mat-icon>settings</mat-icon>
      <mat-icon>settings</mat-icon>
      <mat-icon>settings</mat-icon>
    </div>
    <h1>Your browser is unsupported</h1>
    <h2>Please switch to a more modern browser to view this content</h2>
  </mat-card-content>
</mat-card>
