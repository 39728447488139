import { Component, OnInit, HostBinding } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import {
  OnSIPWindow,
  addIgnoredCookies,
  ignorableCookies
} from "../../../../common/interfaces/global";

import { supportsOnSIP } from "../../../../../web-components/src/support"; // stub
import { TeamPageCustomIconRegistryService } from "./team-page-custom-icon-registry.service";
import { DomainService } from "../domain.service";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";

declare const window: OnSIPWindow;

@Component({
  selector: "onsip-team-page",
  templateUrl: "./team-page-app.component.html"
})
export class TeamPageAppComponent implements OnInit {
  @HostBinding("class.mat-typography")
  _dontUse = true;

  constructor(
    private domainService: DomainService,
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private translate: TranslateService,
    // @ts-ignore: noUnusedLocals
    private customIconRegistryService: TeamPageCustomIconRegistryService
  ) {}

  ngOnInit(): void {
    this.noAuthOrgSummaryService.noAuthOrganizationSummaryRead(this.domainService.get());
    this.translate.setDefaultLang("en");
    this.translate.use(this.translate.getBrowserLang() || "en");
    this.initIgnoredCookies();
    document.title = "Powered by OnSIP";
  }

  /**
   * All team and personal pages exist in a subdomain of onsip.com.
   * OnSIP, as a business, has a variety of web sites running including
   * www.onsip.com and admin.onsip.com which serve cookies up to visitors.
   * Instacall looks for certain cookies to and passes them to the app so
   * that vistor context may be obtained, but there are cases where this
   * leads to incorrect assumptions. To avoid this we are telling Instacall
   * ingore certain cookies in the context of team and persal pages.
   *
   * For example, OnSIP is a HubSpot customer and thus vistors to OnSIP
   * web properties are being served a "hubspotutk" cookie in the *.onsip.com
   * domain. Thus when those vistors then visit a team page (i.e. acme.onsip.com)
   * that cookie become available. But that's OnSIP's cookie referencing a contact
   * in OnSIP's HubSpot account which is not a HubSpot account the organization
   * that "owns" the team page as access to and which otherwise would lead to the
   * users in that organization attempting to utilize that cookie to fetch contextual
   * information from HubSpot which would fail. So we tell InstaCall to ingore the
   * HubSpot cookie to avoid the case.
   */
  initIgnoredCookies() {
    const cookiesToIgnore = [
      ignorableCookies._GA, // Google
      ignorableCookies.HUBSPOTUTK // HubSpot
    ];

    // In development don't ignore cookies.
    // Might be undesireable depending on what you are working on...
    if (/localhost/.test(location.hostname)) {
      return;
    }

    // OnSIP's own team page, we want cookies which belong to us...
    if (window.location.href === "https://team.onsip.com/") {
      return;
    }
    if (supportsOnSIP) {
      window.onsip = (window as OnSIPWindow).onsip || {};
      addIgnoredCookies(cookiesToIgnore);
    }
  }
}
