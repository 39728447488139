import { Config } from "../../../common/config";
import { CoreModule } from "../../../common/modules/core/core.module";
import { ConsoleService } from "../../../common/services/console/console.service";
import { LocalizationService } from "../../../common/services/localization.service";
import { LogTarget } from "../../../common/services/logging/log.target";
import { cons, consoleLogTarget } from "../app.config";

import { TeamPageModule } from "../../features/teamPage/team-page.module";
import { TeamPageAppRoutingModule } from "./teamPageAppRouting.module";
import { TeamPageLifeCycleHookApiResourceModule } from "./api/team-page-lifecycle-hook-api-resource.module";

import { TeamPageAppComponent } from "../../features/teamPage/teamPageApp/team-page-app.component";

import { ErrorHandler, NgModule, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, RouteReuseStrategy } from "@angular/router";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { CustomReuseStrategy } from "../phone/customReuseStrategy";

import { AnalyticsService } from "../../features/teamPage/analytics.service";
import { Analytics } from "../../../../web-components/src/analytics";
import { SentryErrorHandler } from "../sayso/sentry-error-handler";

// i18n mobile translations imports
import { CustomTranslateLoader } from "../../i18n/translate-loader";

import { init as sentryInit } from "@sentry/browser";
import { Dedupe, ExtraErrorData } from "@sentry/integrations";

// for AoT compilation
export function LoaderFactory() {
  return new CustomTranslateLoader();
}

declare let document: any; // there is no document on mobile

if (!/localhost/.test(document.location.host)) {
  sentryInit({
    dsn: "https://108eb408e5ef49ad8882d17a5d62f778@sentry.io/1286352",
    integrations: [new Dedupe(), new ExtraErrorData()],
    environment: !/localhost/.test(document.location.host) ? "production" : "debug",
    release: Config.VERSION_NUMBER,
    beforeBreadcrumb: breadcrumb => {
      breadcrumb.data = breadcrumb.data || {};
      delete breadcrumb.data.logger;
      delete breadcrumb.data.extra;
      return breadcrumb;
    }
  });
}

import { initializeApp } from "../../libraries/firebase/app";

initializeApp(["firestore", "database"]);

@NgModule({
  bootstrap: [TeamPageAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule.forRoot([
      { provide: ConsoleService, useFactory: cons },
      { provide: LogTarget, useFactory: consoleLogTarget, deps: [ConsoleService], multi: true }
    ]),
    TeamPageModule,
    TeamPageLifeCycleHookApiResourceModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LoaderFactory,
        deps: [HttpClient]
      }
    }),
    TeamPageAppRoutingModule // must be last
  ],
  declarations: [TeamPageAppComponent],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    // HACK: the CustomReuseStrategy we use for jiffyphone is generic enough to be double-used here
    //       there's some superfluous logic relating to jiffyphone's routes but it doesn't affect anything
    //       (unless in the future teampage gets a 'contact or 'call' route)
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: AnalyticsService, useClass: Analytics, deps: [] },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: LocalizationService) => localizationService.getLocale()
    }
  ]
})
export class TeamPageApp {}
