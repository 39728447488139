import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

// this should be imported only in team-page-app.component.ts

@Injectable({ providedIn: "root" })

/** Register svgs as mat-icons */
export class TeamPageCustomIconRegistryService {
  constructor(private domSanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon(
      "facebook_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../resources/img/icons/facebook_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "linkedin_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../resources/img/icons/linkedin_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "twitter_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../resources/img/icons/twitter_icon.svg")
    );
  }
}
