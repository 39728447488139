// TODO this file predates robust API -> javascript conversion types
// the things that use this should get converted and this file should be deleted

import { NoAuthUserSummary } from "@onsip/common/services/api/resources/noAuthUserSummary/no-auth-user-summary";
import { ApiNoAuthUserSummaryAddress } from "../../../common/services/api/apiResponse/address";
import { ApiNoAuthUserSummary } from "../../../common/services/api/apiResponse/no-auth-user-summary";

export { ApiNoAuthUserSummary };

export interface TeamMember {
  addresses: Array<string>;
  blurb: string;
  buttonConfiguration: string;
  domain: string;
  email: string;
  facebook: string;
  linkedin: string;
  name: string;
  orgId: string;
  phone: string;
  title: string;
  twitter: string;
  userAvatarUrl: string;
  userId: string;
  username: string;
  visible: boolean;
}

export class NoAuthUserSummaryResult implements ApiNoAuthUserSummary {
  Addresses: { Address: ApiNoAuthUserSummaryAddress | Array<ApiNoAuthUserSummaryAddress> };
  Blurb: string;
  ButtonConfiguration: string;
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string;
  Domain: string;
  ExposePii: "true" | "false";
  Facebook: string;
  Linkedin: string;
  OrganizationId: string;
  TeamPageVisible: "true" | "false";
  Title: string;
  Twitter: string;
  UserAvatarUrl: string;
  UserId: string;

  static from(item: ApiNoAuthUserSummary) {
    return new NoAuthUserSummaryResult(item);
  }

  constructor(item: ApiNoAuthUserSummary) {
    this.Addresses = item.Addresses;
    this.Blurb = item.Blurb;
    this.ButtonConfiguration = item.ButtonConfiguration;
    this.ContactEmail = item.ContactEmail;
    this.ContactName = item.ContactName;
    this.ContactPhone = item.ContactPhone;
    this.Domain = item.Domain;
    this.ExposePii = item.ExposePii;
    this.Facebook = item.Facebook;
    this.Linkedin = item.Linkedin;
    this.OrganizationId = item.OrganizationId;
    this.TeamPageVisible = item.TeamPageVisible;
    this.Title = item.Title;
    this.Twitter = item.Twitter;
    this.UserAvatarUrl = item.UserAvatarUrl;
    this.UserId = item.UserId;
  }
}

export function toTeamMember(result: NoAuthUserSummary): TeamMember {
  /* For username: A User (single person) can have multiple aors, which means multiple usernames-
   * we need a single username to refer to them by to generate their personal page link
   * solution: take the first of the usernames enumerated in the addresses XML
   */
  return {
    addresses: result.addresses.map(address => address.address),
    blurb: result.blurb,
    buttonConfiguration: "",
    domain: result.domain,
    email: result.contactEmail,
    facebook: result.facebook,
    linkedin: result.linkedin,
    name: result.contactName,
    orgId: result.organizationId,
    phone: result.contactPhone,
    title: result.title,
    twitter: result.twitter,
    userId: result.userId,
    username: result.addresses.length > 0 ? result.addresses[0].username : "",
    userAvatarUrl: result.userAvatarUrl,
    visible: result.teamPageVisible
  };
}
