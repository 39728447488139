import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";

import { AFKService } from "./afk.service";

import { supportsOnSIP } from "../../../../../web-components/src/support"; // stub

@Component({
  selector: "onsip-page-warning-bar",
  templateUrl: "./warning-bar.component.html",
  styleUrls: ["./warning-bar.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningBarComponent implements OnInit {
  text!: Observable<string>;

  constructor(private afkService: AFKService) {}

  ngOnInit(): void {
    /**
     * let's not follow the example of wed/desktop's warningbar and make it hideously overcomplicated
     * it's just gunna be a basic observable of a string
     * each 'case' is gunna come from a source observable of a boolean, where all the sources are going to get thrown into the combineLatest operator
     * then, we can do logic on all the sources and determine which message we want to display
     * when i wrote this, there were only 2 messages possible- so if this ends up not working in the future don't get mad
     */
    this.text = combineLatest([
      of(supportsOnSIP), // browserSupport
      this.afkService.allAFK // allAFK
    ]).pipe(
      map(([browserSupport, allAFK]) => {
        if (!browserSupport) {
          return "You are not able to make calls using this browser. Upgrade or switch browsers to connect with a representative.";
        } else if (allAFK) {
          return "All of our representatives are currently offline";
        } else {
          return "";
        }
      })
    );
  }
}
