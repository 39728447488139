<mat-toolbar class="sidenav-mobile"
  *ngIf="(sideNavView | async) === false && (didComplete | async)">
  <onsip-side-bar></onsip-side-bar>
</mat-toolbar>
<mat-sidenav-container class="team-view-container">
  <mat-sidenav mode="side" class="sidenav"
    *ngIf="didComplete | async"
    [opened]="sideNavView | async"
    [fixedInViewport]="true">
    <onsip-side-bar></onsip-side-bar>
  </mat-sidenav>
  <mat-sidenav-content class="team-view-content">
    <onsip-page-warning-bar class="content"></onsip-page-warning-bar>
    <ng-container *ngIf="(teamMembers | async)?.length">
      <onsip-team-view
        id="onsip-team-view"
        class="content"
        [data]="(teamMembers | async)!">
      </onsip-team-view>
    </ng-container>

    <ng-container *ngIf="hasNoTeamMembers | async">
      <onsip-no-people></onsip-no-people>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
