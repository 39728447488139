import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../modules/material/material.module";

import { TeamViewComponent } from "./teamView/team-view.component";
import { TeamViewContainerComponent } from "./teamViewContainer/team-view-container.component";
import { GridItemComponent } from "./gridItem/grid-item.component";
import { PersonalViewComponent } from "./personalView/personal-view.component";
import { PersonalViewContainerComponent } from "./personalViewContainer/personal-view-container.component";
import { SideBarComponent } from "./sideBar/side-bar.component";
import { NoEntryComponent } from "./noEntry/no-entry.component";
import { NotPersonComponent } from "./notPerson/not-person.component";
import { NoPeopleComponent } from "./noPeople/no-people.component";
import { WarningBarComponent } from "./warningBar/warning-bar.component";
import { OnSIPElementComponent } from "./onsipElement/onsip-element.component";
import { PipesModule } from "../shared/pipes/pipes.module";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, PipesModule, MaterialModule],
  declarations: [
    TeamViewContainerComponent,
    TeamViewComponent,
    GridItemComponent,
    PersonalViewComponent,
    PersonalViewContainerComponent,
    SideBarComponent,
    NoEntryComponent,
    NotPersonComponent,
    NoPeopleComponent,
    WarningBarComponent,
    OnSIPElementComponent
  ],
  exports: [
    PersonalViewComponent,
    TeamViewContainerComponent,
    NotPersonComponent,
    NoPeopleComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TeamPageModule {}
