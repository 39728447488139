import { Pipe, PipeTransform } from "@angular/core";
import { isBrightColor } from "@onsip/common/interfaces/get-contrast-color";

/** ### Strategy for custom foreground colors
 *  Is the input color dark enough to display on white background?
 *  If yes return back input color else, return black.
 */
@Pipe({ name: "textColorForWhiteBackground" })
export class TextColorForWhiteBackground implements PipeTransform {
  transform(color: string | undefined | null): string {
    return color ? (isBrightColor(color) ? "#000000" : color) : "initial";
  }
}
