<mat-card teampage-grid-card [routerLink]="[username]" class="mat-elevation-z0">
  <img mat-card-image *ngIf="userAvatarUrl; else defaultAvatar"
    class="avatar"
    [src]="userAvatarUrl"
    alt="Avatar Image"/>
  <ng-template #defaultAvatar>
    <div class="default-avatar-container">
      <mat-icon mat-card-image class="default avatar">account_circle</mat-icon>
    </div>
  </ng-template>
  <div
    class="dot"
    [class.available]="(globallyAvailable | async) || ((audioAvailable | async) && (!(elementDisabled | async)!.disabled || (elementDisabled | async)!.reason === 'in-use'))"
    [class.busy]="busy | async">
  </div>
  <mat-card-content>
    <div class="name">{{name}}</div>
    <div class="title">{{title}}</div>
  </mat-card-content>
  <mat-card-actions>
    <onsip-element-component
      (click)="$event.stopPropagation()"
      [grid]="true"
      [target]="userId"
      [targetDisplay]="name"
      [organizationId]="oid"
      [audio]="(audioAvailable | async)!"
      [video]="false"
      [audioAvailable]="(globallyAvailable | async)! && ((audioAvailable | async)! || ((busy | async) === false))"
      [videoAvailable]="(globallyAvailable | async)! && ((videoAvailable | async)! || ((busy | async) === false))"
      [color]="(color | async)!"
      [disabled]="(elementDisabled | async)!.disabled">
    </onsip-element-component>
    <onsip-element-component
      (click)="$event.stopPropagation()"
      [grid]="true"
      [target]="userId"
      [targetDisplay]="name"
      [organizationId]="oid"
      [audio]="(audioAvailable | async)!"
      [video]="true"
      [audioAvailable]="(globallyAvailable | async)! && ((audioAvailable | async)! || ((busy | async) === false))"
      [videoAvailable]="(globallyAvailable | async)! && ((videoAvailable | async)! || ((busy | async) === false))"
      [color]="(color | async)!"
      [disabled]="(elementDisabled | async)!.disabled">
    </onsip-element-component>
  </mat-card-actions>
</mat-card>
