import { Component, ChangeDetectionStrategy, NgZone } from "@angular/core";

import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { AFKService } from "../warningBar/afk.service";
import { TeamMemberComponent } from "../team-member.component";
import { AnalyticsService } from "../analytics.service";

@Component({
  selector: "onsip-grid-item",
  templateUrl: "./grid-item.component.html",
  styleUrls: ["./grid-item.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemComponent extends TeamMemberComponent {
  constructor(
    zone: NgZone,
    noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    afkService: AFKService,
    analyticsService: AnalyticsService
  ) {
    super(zone, noAuthOrgSummaryService, afkService, analyticsService);
  }
}
