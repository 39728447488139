import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableProdMode } from "@angular/core";
import "../../libraries/firebase/side-effect";
import "../../libraries/firebase/side-effect-database";
import "../../libraries/firebase/side-effect-firestore";
import { TeamPageApp } from "./teamPageApp.module";

declare let document: any; // there is no document on mobile

// Enable production mode unless running locally
if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(TeamPageApp);
