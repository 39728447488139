import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { of } from "rxjs";
import { tap } from "rxjs/operators";

import { supportsOnSIP } from "../../../../web-components/src/support";

export const BrowserSupportPageAppGuard = () => {
  const router = inject(Router);

  return of(supportsOnSIP).pipe(
    tap(result => !result && router.navigate(["/no-entry"], { skipLocationChange: true }))
  );
};
