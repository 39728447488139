import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { TeamMember } from "../team-member";

@Component({
  selector: "onsip-team-view",
  templateUrl: "./team-view.component.html",
  styleUrls: ["./team-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamViewComponent implements OnInit {
  @Input() data: Array<TeamMember> = [];

  rowHeight = "400px";
  gutterSize = "50px";
  cols!: Observable<number>;

  constructor(private breakpoint: BreakpointObserver) {}

  ngOnInit() {
    this.cols = this.breakpoint
      .observe([
        Breakpoints.XLarge,
        Breakpoints.Large,
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ])
      .pipe(
        map(state => {
          if (state.breakpoints[Breakpoints.XLarge]) return 4;
          if (state.breakpoints[Breakpoints.Large]) return 3;
          if (state.breakpoints[Breakpoints.Medium] || state.breakpoints[Breakpoints.Small]) {
            return 2;
          }
          return 1;
        })
      );
  }
}
