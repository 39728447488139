import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { ConfigurationWithoutUuid } from "../../../../common/interfaces/configuration";

@Component({
  selector: "onsip-element-component",
  templateUrl: "./onsip-element.component.html",
  styleUrls: ["./onsip-element.scss"]
})
export class OnSIPElementComponent implements ConfigurationWithoutUuid {
  @ViewChild("webComponentEl") webComponentEl!: ElementRef;

  @Input() grid = true;

  @Input() audio = true;
  @Input() video = false;
  @Input() allowScreenshare = true;
  @Input() target!: string;
  @Input() targetType: "topic" | "user" | "aor" = "user";
  @Input() targetDisplay!: string;
  @Input() organizationId!: number;
  @Input() voicemailbox?: string;

  @Input() audioAvailable = false;
  @Input() videoAvailable = false;
  @Input() color!: string;
  @Input() disabled!: boolean;
}
