<div class="teamview--grid-container">
  <mat-grid-list role="list" [cols]="cols | async" [rowHeight]="rowHeight" [gutterSize]="gutterSize">
    <ng-container
      *ngFor="let person of data">
      <mat-grid-tile teampage-mat-grid-tile role="listitem">
        <onsip-grid-item
          [data]="person">
        </onsip-grid-item>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
