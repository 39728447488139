<mat-card teampage-whitepage-card class="mat-elevation-z0">
  <mat-card-content>
    <h1 class="question-mark">?</h1>
    <div class="person-icon-container">
      <mat-icon>account_circle</mat-icon>
      <mat-icon>account_circle</mat-icon>
      <mat-icon>account_circle</mat-icon>
    </div>
    <h1>Sorry! This team currently has no members.</h1>
  </mat-card-content>
</mat-card>
