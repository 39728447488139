import { ErrorHandler, Injectable } from "@angular/core";
import { captureException } from "@sentry/browser";

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(error: any) {
    captureException(error.originalError || error);
    super.handleError(error);
  }
}
