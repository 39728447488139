import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { TeamViewContainerComponent } from "../../features/teamPage/teamViewContainer/team-view-container.component";
import { PersonalViewContainerComponent } from "../../features/teamPage/personalViewContainer/personal-view-container.component";
import { NoEntryComponent } from "../../features/teamPage/noEntry/no-entry.component";
import { NotPersonComponent } from "../../features/teamPage/notPerson/not-person.component";

import { BrowserSupportPageAppGuard } from "./browser-support-teampage-route.guard";
import { PersonalPageRouteGuard } from "./personal-page-route.guard";
import { PersonalPageRouteResolver } from "./personal-page-route.resolver";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "no-entry", component: NoEntryComponent },
        {
          path: "not-person",
          component: NotPersonComponent,
          canActivate: [BrowserSupportPageAppGuard]
        },
        {
          path: ":username",
          component: PersonalViewContainerComponent,
          canActivate: [BrowserSupportPageAppGuard],
          resolve: { teamMember: PersonalPageRouteResolver }
        },
        {
          path: "",
          component: TeamViewContainerComponent,
          canActivate: [BrowserSupportPageAppGuard, PersonalPageRouteGuard]
        },
        {
          path: "**",
          component: TeamViewContainerComponent,
          canActivate: [BrowserSupportPageAppGuard, PersonalPageRouteGuard]
        }
      ],
      {
        onSameUrlNavigation: "reload"
      }
    )
  ],
  exports: [RouterModule]
})
export class TeamPageAppRoutingModule {}
