import { Injectable } from "@angular/core";
import { domain } from "../../apiParams/domain";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiResourceService } from "../api-resource.service";
import {
  ApiNoAuthUserSummary,
  NoAuthUserSummary,
  apiNoAuthUserSummaryToNoAuthUserSummary as cleanNoAuthUserSummary
} from "./no-auth-user-summary";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
export { NoAuthUserSummary };

const debug = false;

@Injectable({ providedIn: "root" })
export class NoAuthUserSummaryService extends ApiResourceService<NoAuthUserSummary> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "NoAuthUserSummary", "userId");
    debug && this.state.subscribe(state => console.warn("NoAuthUserSummaryService", state));
  }

  noAuthUserSummaryBrowse(params: {
    Domain?: string;
    Limit?: string;
  }): ApiPromiseState<NoAuthUserSummary> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.NoAuthUserSummaryBrowse,
        Domain: params.Domain ? params.Domain : this.store.state.pipe(domain()),
        Limit: params.Limit || 150
      }
    });
    return this.promiseState.toPromise(ApiAction.NoAuthUserSummaryBrowse);
  }

  noAuthUserSummaryRead(params: {
    Username: string;
    Domain?: string;
    pluck?: boolean;
  }): ApiPromiseState<NoAuthUserSummary> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.NoAuthUserSummaryRead,
        Domain: params.Domain ? params.Domain : this.store.state.pipe(domain()),
        Username: params.Username,
        pluck: params.pluck || true
      }
    });
    return this.promiseState.toPromise(ApiAction.NoAuthUserSummaryRead);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.NoAuthUserSummaryBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiNoAuthUserSummary>>(
              response,
              action,
              "NoAuthUserSummary",
              "NoAuthUserSummaries"
            ).map(cleanNoAuthUserSummary),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.NoAuthUserSummaryRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              cleanNoAuthUserSummary(
                extractData<ApiNoAuthUserSummary>(response, action, "NoAuthUserSummary")
              )
            ],
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
