const debug = true;
declare let window: any;

/**
 * To state the obvious, this TypeScript file needs to be transpiled to an appropriate target.
 * For example, transpiling to ES6 and then checking for ES6 support likely doesn't make any sense.
 */

export const supportsNoPhantomJs = (() => {
  const phantomRules: { name: string; rule: RegExp } = {
    name: "phantomjs",
    rule: /PhantomJS\/([0-9.]+)(:?\s|$)/
  };
  if (typeof window.navigator !== "undefined") {
    if (window.navigator.userAgent) {
      const match: Array<any> | null = phantomRules.rule.exec(window.navigator.userAgent);
      // eslint-disable-next-line no-null/no-null
      return match === null; // Match is null if the browser is not PhantomJS which will return true for nonEdge browsers
    }
  }
  return false;
})();

export const supportsNoEdge = (() => {
  const edgeRules: { name: string; rule: RegExp } = {
    name: "edge",
    rule: /Edge\/([0-9._]+)/
  };
  if (typeof window.navigator !== "undefined") {
    if (window.navigator.userAgent) {
      const match: Array<any> | null = edgeRules.rule.exec(window.navigator.userAgent);
      // eslint-disable-next-line no-null/no-null
      return match === null; // Match is null if the browser is not Edge which will return true for nonEdge browsers
    }
  }
  return false;
})();

/** safari 13 is the first supported version as of 3/11/21 */
const SAFARI_MIN_VERSION = 13;
export const supportsNoOldSafari = (() => {
  const safariRules: { name: string; rule: RegExp } = {
    name: "safari",
    rule: /Version\/([0-9]+)(?:[._]?[0-9]+)+.*Safari/
  };
  if (typeof window.navigator !== "undefined") {
    if (window.navigator.userAgent) {
      const match: Array<any> | null = safariRules.rule.exec(window.navigator.userAgent);
      return !match || (match[1] && parseInt(match[1]) >= SAFARI_MIN_VERSION);
    }
  }
  return false;
})();

export const supportsES6 = (() => {
  // This is by no means perfect. The goal is simply to get browser coverage.
  // If this test is produces a false postive, add a feature the suspect browser doesn't support.
  // See: https://stackoverflow.com/questions/29046635/javascript-es6-cross-browser-detection

  const features: Record<string, string> = {
    ArrowFunction: "(_ => _)",
    Class: "(class Name {})",
    Const: "const constant = true",
    DefaultParameter: "(function (a = true) {})",
    Destructuring: "let {dest} = { dest: true }",
    DestructuringParameter: "(a, { b = true }) => {}", // https://bugzilla.mozilla.org/show_bug.cgi?id=1342837
    ForOf: "for (const b of [])",
    Generator: "(function*() {})",
    SuperExpression: "({ method() { super.prop }} )"
  };

  function evaluate(code: string): boolean {
    try {
      eval(code);
      return true;
    } catch (e) {
      return false;
    }
  }

  function supports(featuresToCheck: Array<string>): boolean {
    let code = "(function(){";
    featuresToCheck.forEach(feature => {
      if (Object.prototype.hasOwnProperty.call(features, feature)) {
        code += features[feature] + "; ";
      }
    });
    code += "})()";
    return evaluate(code);
  }

  function checkES6(): boolean {
    const methodSupport =
      "function" === typeof Object.assign &&
      "function" === typeof Object.freeze &&
      "function" === typeof Symbol;

    const syntaxSupport = supports([
      "ArrowFunction",
      "Class",
      "Const",
      "DefaultParameter",
      "Destructuring",
      "DestructuringParameter",
      "ForOf",
      "Generator",
      "SuperExpression"
    ]);
    return methodSupport && syntaxSupport;
  }

  return checkES6();
})();

export const supportsES6Old = (() => {
  //
  // See: https://stackoverflow.com/questions/29046635/javascript-es6-cross-browser-detection
  //
  if (typeof Symbol === "undefined") {
    return false;
  }
  try {
    eval("class Foo {}");
    eval("var bar = (x) => x+1");
  } catch (e) {
    return false;
  }
  return true;
})();

export const supportsLocalStorage = (() => {
  return !!window && !!window.localStorage;
})();

export const supportsMediaDevices = (() => {
  return (
    !!navigator &&
    !!navigator.mediaDevices &&
    !!navigator.mediaDevices.enumerateDevices &&
    !!navigator.mediaDevices.getUserMedia
  );
})();

export const supportsRTCPeerConnection = (() => {
  return !!window && !!(window as any).RTCPeerConnection;
})();

export const supportsWebSocket = (() => {
  return !!window && !!(window as any).WebSocket;
})();

/**
 * OnSIP requires ES6 compliance and the following Web APIs (see: https://developer.mozilla.org/en-US/docs/Web/API)
 *  - LocalStorage
 *  - MediaDevices
 *  - RTCPeerConnection
 *  - WebSocket
 */
export const supportsOnSIP = (() => {
  debug && console.log("OnSIP: Browser supports ES6: ", supportsES6);
  debug && console.log("OnSIP: Browser supports LocalStorage: ", supportsLocalStorage);
  debug && console.log("OnSIP: Browser supports MediaDevices: ", supportsMediaDevices);
  debug && console.log("OnSIP: Browser supports RTCPeerConnection: ", supportsRTCPeerConnection);
  debug && console.log("OnSIP: Browser supports WebSocket: ", supportsWebSocket);
  return (
    supportsNoPhantomJs && // block users from using PhantomJS
    supportsNoEdge && // temporary check until button on edge is working
    supportsNoOldSafari &&
    supportsES6 &&
    supportsLocalStorage &&
    supportsMediaDevices &&
    supportsRTCPeerConnection &&
    supportsWebSocket
  );
})();
