import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AFKService {
  allAFK: Observable<boolean>;
  private store: Record<string, boolean> = {};
  private subject = new BehaviorSubject<Record<string, boolean>>({});

  constructor() {
    this.allAFK = this.subject
      .asObservable()
      .pipe(
        map(store => Object.keys(store).length > 0 && Object.keys(store).every(key => store[key]))
      );
  }

  set(id: string, value: boolean): void {
    this.store[id] = value;
    this.subject.next(this.store);
  }

  delete(id: string): void {
    delete this.store[id];
    this.subject.next(this.store);
  }
}
