import {
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  NgZone,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  Directive
} from "@angular/core";
import { Observable, BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { pluck, skipUntil, mapTo, map, filter } from "rxjs/operators";

import { enterZone } from "../../../common/libraries/enter-zone";

import { OnSIPElementComponent } from "./onsipElement/onsip-element.component";

import { AnalyticsService } from "./analytics.service";
import { NoAuthOrganizationSummaryService } from "../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { AFKService } from "./warningBar/afk.service";
import { TeamMember } from "./team-member";
import { UserPresenceListener } from "../../../common/libraries/firebase/realtime/user-presence-listener";
import { DisabledReason } from "../../../common/interfaces/disabled-reason";
import { SaysoEventData } from "../../../common/interfaces/analytics";
import { User } from "../../../common/libraries/firebase/store/user";

function isDisabledReason(arg: unknown): arg is DisabledReason {
  return (
    arg === "init" ||
    arg === "no-configuration" ||
    arg === "configured-disabled" ||
    arg === "bhr" ||
    arg === "no-audio" ||
    arg === "other" ||
    arg === "hostname-whitelist" ||
    arg === "in-use"
  );
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TeamMemberComponent implements TeamMember, OnInit, OnChanges, OnDestroy {
  @ViewChildren(OnSIPElementComponent) set webComponents(comps: QueryList<OnSIPElementComponent>) {
    comps.forEach(comp => {
      const nativeElement = comp.webComponentEl.nativeElement;
      this.mutationObserver = new MutationObserver(mutationsList => {
        mutationsList.forEach(mut => {
          if (mut.attributeName === "disabled" && comp.video === false) {
            // presence dot only for audio side
            this.elementDisabled.next({
              // eslint-disable-next-line no-null/no-null
              disabled: nativeElement.getAttribute("disabled") !== null,
              reason: nativeElement.getAttribute("disabled-reason")
            });
          }
        });
      });
      this.mutationObserver.observe(nativeElement, { attributes: true });
      this.setupAnalyticsListeners(nativeElement);
    });
  }

  @Input() data!: TeamMember;

  @Output() analyticsData = new EventEmitter<Array<boolean>>();

  color = this.noAuthOrgSummaryService.state.pipe(
    filter(state => !state.loading),
    map(state => Object.values(state.state)[0].teamPageInfo.accentColor)
  );
  orgName = this.noAuthOrgSummaryService.state.pipe(
    filter(state => !state.loading),
    map(state => Object.values(state.state)[0].contactOrganization)
  );

  addresses: Array<string> = [];
  blurb = "";
  buttonConfiguration = "";
  domain = "";
  email = "";
  facebook = "";
  linkedin = "";
  name = "";
  orgId = "";
  phone = "";
  title = "";
  twitter = "";
  userAvatarUrl = "";
  userId = "";
  username = "";
  visible = false;

  oid!: number;
  uid!: number;
  busy: Observable<boolean> | undefined;
  audioAvailable: Observable<boolean> | undefined;
  videoAvailable: Observable<boolean> | undefined;
  userPresenceListener: UserPresenceListener | undefined;
  globallyAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firestoreUserListener: User | undefined;
  mutationObserver: MutationObserver | undefined;
  elementDisabled = new BehaviorSubject<{ disabled: boolean; reason: DisabledReason }>({
    disabled: false,
    reason: "init"
  });

  private unsubscriber = new Subscription();

  static toId(val: string | undefined): number | undefined {
    if (val === undefined) return undefined;
    const i = this.toInt(val);
    return i === undefined || i < 1 ? undefined : i;
  }

  static toInt(val: string): number | undefined {
    const parsed = parseInt(val, 10);
    return isNaN(parsed) ? undefined : parsed;
  }

  constructor(
    private zone: NgZone,
    private noAuthOrgSummaryService: NoAuthOrganizationSummaryService,
    private afkService: AFKService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    /** story time:
     * firebase will always lose the race against configuring the button
     * the result is that an available person will flash 'audio-unavailable' until firebase spins up and sets their presence
     * this results in a button being disabled "false positive"
     * this results in the afkservice telling the warning banner to go on- which we don't want at the beginning if someone is available
     * the solution is to simply skip until we get a real emission from the presence listener
     * skip(1) skips the first state which is just the initial state from the BehaviorSubject
     */
    this.initUserAvailability();
    if (!this.audioAvailable) return; // never
    if (!this.videoAvailable) return;
    this.unsubscriber.add(
      combineLatest([this.audioAvailable, this.videoAvailable]).subscribe(data =>
        this.analyticsData.emit(data)
      )
    );

    this.unsubscriber.add(
      this.elementDisabled
        .pipe(skipUntil(this.audioAvailable.pipe(mapTo(true))))
        .subscribe(({ disabled, reason }) => {
          if (!isDisabledReason(reason)) {
            console.log(
              `Received unknown value for attribute "disabled-reason" ${reason} for user id ${this.data.userId}`
            );
          }
          if (disabled && (!reason || (reason as any) === "null")) return; // weird case?
          reason !== "init" &&
            reason !== "in-use" &&
            this.afkService.set(this.data.userId, disabled);
        })
    );
  }

  ngOnChanges(): void {
    if (!this.data) return;
    this.addresses = this.data.addresses;
    this.blurb = this.data.blurb;
    this.buttonConfiguration = this.data.buttonConfiguration;
    this.domain = this.data.domain;
    this.email = this.data.email;
    this.facebook = this.data.facebook;
    this.linkedin = this.data.linkedin;
    this.name = this.data.name;
    this.orgId = this.data.orgId;
    this.phone = this.data.phone;
    this.title = this.data.title;
    this.twitter = this.data.twitter;
    this.userAvatarUrl = this.data.userAvatarUrl;
    this.userId = this.data.userId;
    this.username = this.data.username;
    this.visible = this.data.visible;
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
    if (this.userPresenceListener) {
      this.userPresenceListener.dispose();
      this.userPresenceListener = undefined;
    }
    if (this.firestoreUserListener) {
      this.firestoreUserListener.dispose();
      this.firestoreUserListener = undefined;
    }
    this.afkService.delete(this.data.userId);
    this.mutationObserver && this.mutationObserver.disconnect();
  }

  private validateAndSendEvent(data: any) {
    const checkCustomEventWithActionData = (arg: any): arg is CustomEvent<SaysoEventData> =>
      arg instanceof CustomEvent;
    if (!checkCustomEventWithActionData(data)) {
      return;
    }
    this.analyticsService.trackEvent(data.detail);
  }

  private setupAnalyticsListeners(element: HTMLElement) {
    element.addEventListener("onsipAnalytics", this.validateAndSendEvent.bind(this));
  }

  private initUserAvailability(): void {
    const oid = TeamMemberComponent.toId(this.data.orgId);
    const uid = TeamMemberComponent.toId(this.data.userId);
    if (oid !== this.oid || uid !== this.uid) {
      this.oid = oid as number;
      this.uid = uid as number;
      if (this.userPresenceListener) {
        this.userPresenceListener.dispose();
        this.userPresenceListener = undefined;
      }
      if (this.oid && this.uid) {
        this.userPresenceListener = new UserPresenceListener(this.oid, this.uid);
        this.firestoreUserListener = new User();
        this.busy = this.userPresenceListener.state.pipe(enterZone(this.zone), pluck("busy"));
        this.audioAvailable = this.userPresenceListener.state.pipe(
          enterZone(this.zone),
          pluck("audioAvailable")
        );
        this.videoAvailable = this.userPresenceListener.state.pipe(
          enterZone(this.zone),
          pluck("videoAvailable")
        );

        this.unsubscriber.add(
          combineLatest([
            this.audioAvailable,
            this.firestoreUserListener.state.pipe(enterZone(this.zone))
          ]).subscribe(([realtime, store]) => {
            if (store.availability === "undefined") {
              this.globallyAvailable.next(realtime);
            } else {
              this.globallyAvailable.next(store.availability === "available");
            }
          })
        );

        this.userPresenceListener.start();
        this.firestoreUserListener.start(this.oid, this.uid);
      }
    }
  }
}
