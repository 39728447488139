import { Tracker } from "../../src/common/libraries/analytics/tracker";
import { SaysoEventData } from "../../src/common/interfaces/analytics";

export class Analytics {
  private eventData: SaysoEventData = {
    action: "load"
  };
  private tracker: Tracker;

  constructor(product?: string, buttonType?: string, organizationId?: number) {
    this.tracker = new Tracker("instacall2", {
      schemas: [
        {
          collector: "fivetran",
          name: "instacall",
          schema: "https://app.onsip.com/analytics/InstacallSchema-analytics-v1.0.4.json"
        },
        {
          name: "instacall",
          schema: "iglu:com.onsip/instacall/jsonschema/1-0-0",
          collector: "snowcat"
        }
      ]
    });
    this.eventData.product = product;
    this.eventData.button_type = buttonType;
    this.eventData.organization_id = organizationId;
  }

  trackEvent(actionData: SaysoEventData) {
    if (actionData.action === "click") {
      // Clear old data
      this.resetSavedEventData();
    }

    this.eventData.action = actionData.action;

    // Hold on to this data for future events
    if (actionData.product) {
      this.eventData.product = actionData.product;
    }
    if (actionData.button_type) {
      this.eventData.button_type = actionData.button_type;
    }
    if (actionData.organization_id) {
      this.eventData.organization_id = actionData.organization_id;
    }
    if (actionData.ouid) {
      this.eventData.ouid = actionData.ouid;
    }
    if (actionData.video_available !== undefined) {
      // Check if not undefined so that false can go into the event
      this.eventData.video_available = actionData.video_available;
    }
    if (actionData.audio_available !== undefined) {
      this.eventData.audio_available = actionData.audio_available;
    }
    if (actionData.video_call !== undefined) {
      this.eventData.video_call = actionData.video_call;
    }
    if (actionData.audio_call !== undefined) {
      this.eventData.audio_call = actionData.audio_call;
    }
    if (actionData.target) {
      this.eventData.target = actionData.target;
    }
    if (actionData.target_type) {
      this.eventData.target_type = actionData.target_type;
    }
    if (actionData.audio_config !== undefined) {
      this.eventData.audio_config = actionData.audio_config;
    }
    if (actionData.video_config !== undefined) {
      this.eventData.video_config = actionData.video_config;
    }
    if (actionData.caller_display_name !== undefined) {
      this.eventData.caller_display_name = actionData.caller_display_name;
    }
    if (actionData.config_uuid !== undefined) {
      this.eventData.config_uuid = actionData.config_uuid;
    }
    if (actionData.time_since_call_attempt !== undefined) {
      this.eventData.time_since_call_attempt = actionData.time_since_call_attempt;
    }
    if (actionData.time_since_call_answer !== undefined) {
      this.eventData.time_since_call_answer = actionData.time_since_call_answer;
    }

    this.tracker.trackEvent("instacall", this.eventData);
  }

  private resetSavedEventData() {
    this.eventData.ouid = undefined;
    this.eventData.caller_display_name = undefined;
    this.eventData.video_available = undefined;
    this.eventData.audio_available = undefined;
    this.eventData.video_call = undefined;
    this.eventData.audio_call = undefined;
    this.eventData.time_since_call_answer = undefined;
    this.eventData.time_since_call_attempt = undefined;
  }
}
